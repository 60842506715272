import React from 'react';
import { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Button, Layout, Table, Tag, Input, Spin, Alert } from 'antd';
import { useQuery } from 'react-apollo';

import { Artist } from '../artist';
import { artistFetchAll } from '../artist.queries';
import { PageTitle } from '../../components/layouts/page/PageTitle';
import { ArtistFetchAll_artists_artworks } from '../__generated__/ArtistFetchAll';

const columns = [
  {
    title: 'Blaze',
    dataIndex: 'nickName',
    key: 'nickName',
  },
  {
    title: 'Full Name',
    dataIndex: 'fullName',
    render: (text: any, record: Artist) => (
      <span>
        {record.firstName} {record.lastName}
      </span>
    ),
  },
  {
    title: '# of artworks',
    dataIndex: 'artworks',
    key: 'artworks',
    render: (text: ArtistFetchAll_artists_artworks[]) => <span>{text.length}</span>,
  },
  {
    title: 'Country',
    dataIndex: 'country',
    key: 'country',
  },
  {
    title: 'City',
    dataIndex: 'city',
    key: 'city',
  },
  {
    title: 'Status',
    dataIndex: 'status',
    key: 'status',
    render: (status: string) => (
      <Tag color={status === 'active' ? 'green' : status === 'rejected' ? 'red' : 'blue'}>
        {status}
      </Tag>
    ),
  },
  {
    title: 'Action',
    key: 'action',
    render: (text: any, record: Artist) => (
      <span>
        <Link to={`/artists/detail/${record._id}`}>
          Detail
        </Link>
      </span>
    ),
  },
];

const rowSelection = {
  onChange: (selectedRowKeys: any, selectedRows: any) => {
    console.log(`selectedRowKeys: ${selectedRowKeys}`, 'selectedRows: ', selectedRows);
  },
  getCheckboxProps: (record: any) => ({
    disabled: record.name === 'Disabled User', // Column configuration not to be checked
    name: record.name,
  }),
};


export const ArtistListPage = () => {
  const navigate = useNavigate();
  //Search bar variable
  const [searchText, setSearchText] = useState('');
  const { loading, error, data } = useQuery(artistFetchAll);
  const [artistsResults, setArtistsResults] = useState<Artist[]>([]);

  const filterArtists = (artists: any[]) => {

    const filteredArtists = artists.filter(artist =>
      artist.firstName.toLowerCase().includes(searchText.toLowerCase()) ||
      artist.lastName.toLowerCase().includes(searchText.toLowerCase()) ||
      artist.nickName.toLowerCase().includes(searchText.toLowerCase()) ||
      artist.email.toLowerCase().includes(searchText.toLocaleLowerCase())
    );

    setArtistsResults(filteredArtists); // Update the artistsResults state with the filtered data
  };

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchText(e.target.value);
  };

  // Update the filtered artists when the data or searchText changes
  useEffect(() => {
    if (data && data.artists) {
      // Filter the artists based on the search text and update the state
      filterArtists(data.artists);
    }
  }, [data, searchText]); // Re-run the effect when data or searchText changes


  return (
    <Layout.Content>
      <PageTitle
        title="Artists"
        actions={
          <Button onClick={() => navigate('/artists/create')} title={'new artist'} type={'primary'}>
            new artist
          </Button>
        }
      />

      <Input
        placeholder="Search artists"
        value={searchText}
        onChange={handleSearch}
        style={{ marginBottom: 4, width: '50%', minWidth: 200 }}
      />
      <p style={{ marginBottom: 20}}><small>Search in firstName, lastName, nickName, email</small></p>
      
      {loading && <Spin> Loading artists ...</Spin>}
      {error && <Alert message={error.message} type="error" />}

      {data && <Table columns={columns} dataSource={artistsResults} rowKey={record => record._id} rowSelection={rowSelection} />}
    </Layout.Content>
  );
};
